
import { Component, Prop, Vue } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import LsPagination from "@/components/ls-pagination.vue";

@Component({
  components: {
    LsDialog,
    LsPagination,
  },
})
export default class OrderPane extends Vue {
  @Prop() value: any;
  @Prop() pager!: any;

  selectIds: any = [];

  remarks = ""; //商家备注

  // 获取订单信息
  getOrderLists() {
    (this.$parent as any).getOrderLists();
  }

  // 选择某条数据
  selectionChange(val: any[]) {
    this.selectIds = val.map((item) => item.id);
  }

  // 全选
  selectAll() {
    (this.$refs.paneTable as any).toggleAllSelection();
  }

  // 去订单详情
  toOrder(id: any) {
    this.$router.push({
      path: "/order/order_detail",
      query: { id },
    });
  }

  toUser(id: any) {
    this.$router.push({
      path: "/user/user_details",
      query: { id: id },
    });
  }

  // 取消订单
  orderCancel(id: Number) {}

  // 确认收货
  orderConfirm(id: Number) {}

  async onPrintOrderFunc(row: any) {}

  // 商家备注
  postOrderRemarks(id: any) {}

  // 订单核销
  onSelffetchOrderVerification(id: number) {}
}
